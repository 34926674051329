import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PARTICIPANT_NOTE,
  DELETE_PARTICIPANT_NOTE,
  GET_ACTIVITY_PARTICIPANT_ATTENDANCES,
  UPDATE_PARTICIPANT_NOTE,
} from "../../../graphql";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ActionButton } from "../../../components";
import { getSortedOptions } from "../../../helpers/dataHelpers";
import { AppStyles, Colors } from "../../../theme";
import moment from "moment";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { INVALID_NOTE_INFO } from "../../../constants";
import { css } from "aphrodite";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textAreaScrollable: {
    maxHeight: "calc(1.5em * 5)",
    lineHeight: "1.5",
    overflowY: "auto",
    paddingRight: "10px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "500px",
    margin: "0 20px",
    position: "relative",
  },
  dialogPaper: {
    width: "80%",
    maxHeight: 435,
  },
  addBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  updateBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  deleteBtn: {
    background: Colors.red2,
    color: Colors.white,
    border: `1px solid ${Colors.red2}`,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    "&:hover": {
      color: Colors.red2,
      background: Colors.white,
      boxShadow: "none",
    },
  },
}));

export default function (props) {
  const classes = useStyles();

  const textAreaRef = useRef(null);
  const [note, setNote] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [activity, setActivity] = useState({});
  const [presentParticipants, setPresentParticipants] = useState([]);
  const [noteInvalid, setNoteInvalid] = useState(false);

  const checkAttendance = (participant, actDate) => {
    const day = _.find(participant.attendances, (curr) => {
      if (!moment(curr.actDate).isSame(activity.actDate, "day")) {
        return false;
      }

      return true;
    });

    return Boolean(day && day.present);
  };

  const [fetchActivity] = useLazyQuery(GET_ACTIVITY_PARTICIPANT_ATTENDANCES, {
    onCompleted: (data) => {
      const attenders = [];

      const activity = {
        id: data.activity?.data?.id,
        ...data.activity?.data?.attributes,
        participants: data.activity?.data?.attributes?.participants?.data?.map(
          (p) => {
            return {
              id: p.id,
              ...p.attributes,
              attendances: p.attributes.attendances.data.map((a) => {
                return {
                  id: a.id,
                  ...a.attributes,
                };
              }),
            };
          }
        ),
      };

      activity.participants.forEach((p) => {
        if (checkAttendance(p, data.activity?.date)) {
          attenders.push(p);
        }
      });

      setActivity(activity);
      setPresentParticipants(attenders);
    },
  });

  const handleInputChange = (e) => {
    setNoteInvalid(false);
    setNote({ ...note, textnote: e.target.value });

    if (textAreaRef.current) {
      const input = textAreaRef.current;
      input.scrollTop = input.scrollHeight;
    }
  };

  useEffect(() => {
    if (props.note.id) {
      setNote(props.note);
    } else {
      setNote({
        participants: [props.participant],
      });
      setPresentParticipants([props.participant]);
    }

    const activityId = props.note.activity?.id;

    if (activityId) {
      fetchActivity({
        variables: {
          activityId,
        },
      });
    }
  }, [props, fetchActivity]);

  const [createNoteReq, { loading: createNoteLoading }] = useMutation(
    CREATE_PARTICIPANT_NOTE,
    {
      onCompleted: (data) => {
        if (props.submitCallback) {
          props.submitCallback("La note a été créée");
        }
      },
    }
  );

  const [updateNoteReq, { loading: updateNoteLoading }] = useMutation(
    UPDATE_PARTICIPANT_NOTE,
    {
      onCompleted: () => {
        if (props.submitCallback) {
          props.submitCallback("La note a été soumise");
        }
      },
    }
  );

  const [deleteNoteReq, { loading: deleteNoteLoading }] = useMutation(
    DELETE_PARTICIPANT_NOTE,
    {
      onCompleted: () => {
        if (props.deleteCallback) {
          props.deleteCallback("Note supprimée");
        }
      },
    }
  );

  const submitNoteDeletion = () => {
    deleteNoteReq({
      variables: {
        id: note.id,
      },
    });
  };

  const submitNote = () => {
    if (!note.textnote) {
      setNoteInvalid(true);
      return null;
    }

    if (note.id) {
      updateNoteReq({
        variables: {
          id: note.id,
          textnote: note.textnote,
          participantIds: note.participants.map((p) => p.id),
        },
      });
    } else {
      createNoteReq({
        variables: {
          textnote: note.textnote,
          participantIds: note.participants.map((p) => p.id),
        },
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            {note.id ? "Modifier la note" : "Créer une note"}
          </h2>
          <form
            className={classes.root}
            style={{
              width: "600px",
              maxWidth: "100%",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={6} justifyContent="space-between">
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="remarque"
                      label="Remarque"
                      fullWidth
                      multiline
                      value={note.textnote}
                      onChange={handleInputChange}
                      classes={{
                        root: classes.textAreaScrollable,
                      }}
                    />
                    {noteInvalid && (
                      <span className={`${css(AppStyles.formError)}`}>
                        {INVALID_NOTE_INFO}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      multiple
                      id="note-participant-tags"
                      disabled={true}
                      options={
                        presentParticipants
                          ? getSortedOptions(presentParticipants, "firstName")
                          : []
                      }
                      getOptionLabel={(option) => {
                        return `${option.firstName} ${option.lastName}`;
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={note.participants || []}
                      onChange={(event, newValue) => {
                        setNote({
                          ...note,
                          participants: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Participants"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              style={{ marginTop: 20 }}
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                {Boolean(note.id) && (
                  <ActionButton
                    className={classes.deleteBtn}
                    title="Supprimer"
                    isLoading={deleteNoteLoading}
                    onClick={() => {
                      setOpenConfirmDialog(true);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={6} a>
                <Box textAlign={"right"}>
                  <ActionButton
                    className={classes.addBtn}
                    title={note.id ? "Sauvegarder" : "Créer"}
                    isLoading={createNoteLoading || updateNoteLoading}
                    onClick={() => {
                      submitNote();
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Dialog
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={openConfirmDialog}
            >
              <DialogTitle id="confirmation-dialog-title">
                Supprimer la note
              </DialogTitle>
              <DialogContent dividers>
                Êtes-vous sûr de vouloir supprimer la note?
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  autoFocus
                  onClick={() => {
                    setOpenConfirmDialog(false);
                  }}
                  color="primary"
                >
                  Annuler
                </Button>
                <Button
                  className={classes.deleteBtn}
                  onClick={() => {
                    setOpenConfirmDialog(false);
                    submitNoteDeletion();
                  }}
                >
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </form>
          <IconButton
            style={{ position: "absolute", top: "0", right: "0" }}
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Fade>
    </Modal>
  );
}
