import moment from "moment";
import { gql } from "@apollo/client";
import { STRAPI_DATE_FORMAT } from "../constants";

export const ACTIVITIES = (date, youthCenterID, servicePointId) => {
  let withServicePoint = "";
  let ycSelector = "";

  if (parseInt(youthCenterID)) {
    ycSelector = `
      youth_center: {
        id: {
          eq: ${youthCenterID}
        }
      }
    `;
  }

  if (parseInt(servicePointId)) {
    withServicePoint = `
      service_point: {
        id: {
          in: [${servicePointId}]
        }
      }
    `;
  }

  return gql`
    query GetActivities {
      activities(filters: {
        date: {
          eq: "${moment(date).format(STRAPI_DATE_FORMAT)}"
        }
        ${ycSelector}
        ${withServicePoint}
      }) {
        data {
          id
          attributes {
            title
            date
            observation
            service_point {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const ACTIVITIES_LISTING = (multipleRegions, SPs) => {
  let ycSelector = [
    "$youthCenterId: ID",
    `youth_center: {
      id: {
        eq: $youthCenterId
      }
      show_in_search_results: {
        eq: true
      }
    }`,
  ];

  let regionSelector = ["", ""];

  if (multipleRegions) {
    ycSelector = ["", ""];

    regionSelector = [
      "$regionIds: [ID]",
      `youth_center: {
        region: {
          id: {
            in: $regionIds
          }
        }
        show_in_search_results: {
          eq: true
        }
      }`,
    ];
  }

  let spSelector = "";

  if (SPs?.length) {
    const ids = SPs.map((sp) => parseInt(sp.id)).join(",");

    spSelector = `last_service_point: {in: [${ids}] }`;
  }

  return gql`
    query GetActivities(
      ${ycSelector[0]}
      $startDate: Date
      $endDate: Date
      $title: String
      $types: [ID]
      $themes: [ID]
      $scenarios: [ID]
      $goals: [ID]
      $tags: [ID]
      $participantTypeId: [ID]
      $categories: [ID]
      $observations: String
      $page: Int
      $pageSize: Int
      ${regionSelector[0]}
    ) {
      activities(pagination: {
        page: $page
        pageSize: $pageSize
      },
      filters: {
        ${ycSelector[1]}
        date: {
          gte: $startDate
          lte: $endDate
        }
        title: {
          contains: $title
        }
        activity_type: {
          id: {
            in: $types
          }
        }
        themes: {
          id: {
            in: $themes
          }
        }
        activity_scenario: {
          id: {
            in: $scenarios
          }
        }
        goals: {
          id: {
            in: $goals
          }
        }
        activity_characteristics: {
          id: {
            in: $categories
          }
        }
        activity_tags: {
          id: {
            in: $tags
          }
        }
        observation: {
          contains: $observations
        }
        participants: {
          participant_types: {
            id: {
              in: $participantTypeId
            }
          }
          ${spSelector}
        }
        ${regionSelector[1]}
      }, sort: "createdAt:desc") {
        meta {
          pagination {
            page
            pageSize
            pageCount
            total
          }
        }
        data {
          id
          attributes {
            title
            date
            start_time
            end_time
            start_date
            end_date
            virtual_presences_count
            activity_involvements {
              data {
                id
                attributes {
                  count
                  activity_theme {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_tag {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_characteristic {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            youth_center {
              data {
                id
                attributes {
                  Name
                  region {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            activity_type {
              data {
                id
                attributes {
                  name
                  showTags
                }
              }
            }
            activity_scenario {
              data {
                id
                attributes {
                  name
                }
              }
            }
            activity_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            service_point {
              data {
                id
                attributes {
                  name
                }
              }
            }
            activity_characteristics {
              data {
                id
                attributes {
                  name
                }
              }
            }
            themes {
              data {
                id
                attributes {
                  name
                }
              }
            }
            goals {
              data {
                id
                attributes {
                  name
                }
              }
            }
            participants {
              data {
                id
                attributes {
                  firstName
                  lastName
                  year
                  month
                  day
                  sexe
                  other
                  participant_types {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  disabilities {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            observation
            youth
            youth_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            grand_public
            public_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_ACTIVITY_TITLES = (youthCenterID) => {
  return gql`
    query getActivities {
      activities(filters: {
        youth_center: {
          id: {
            eq: ${youthCenterID}
          }
        }
      }, sort: "createdAt:desc") {
        data {
          id
          attributes {
            title
          }
        }
      }
    }
  `;
};

export const GET_PARTICIPANT_ACTIVITIES = gql`
  query getActivities($participantID: ID!, $youthCenterID: ID!) {
    activities(filters: {
      youth_center: {
        id: {
          eq: $youthCenterID
        }
      }
      participants: { id: { eq: $participantID } } }) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;

export const PARTICIPANT_NOTES_FOR_ACTIVITY = (activityID) => {
  return gql`
    query getNoteForActivity {
      participantNotes(filters: {
        activity: {
          id: {
            eq: ${activityID}
          }
        }
      }) {
        data {
          id
          attributes {
            textnote
            participants {
              data {
                id
                attributes {
                  firstName
                  lastName
                }
              }
            }
            activity {
              data {
                id
              }
            }
          }
        }
      }
    }
  `;
};

export const NOTES_FOR_PARTICIPANT = gql`
  query getNoteForParticipant($participantId: ID!) {
    participantNotes(
      filters: { participants: { id: { eq: $participantId } } }
    ) {
      data {
        id
        attributes {
          textnote
          createdAt
          participants {
            data {
              id
              attributes {
                firstName
                lastName
              }
            }
          }
          activity {
            data {
              id
              attributes {
                title
                date
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_PARTICIPANT_ATTENDANCES = gql`
  query getActivityParticipantAttendances($activityId: ID!) {
    activity(id: $activityId) {
      data {
        id
        attributes {
          date
          participants {
            data {
              id
              attributes {
                firstName
                lastName
                first_attendance_date
                attendances {
                  data {
                    id
                    attributes {
                      date
                      present
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PARTICIPANT_NOTE = gql`
  mutation createParticipantNote(
    $textnote: String!
    $activity: ID
    $participantIds: [ID]
    $youth_center: ID
  ) {
    createParticipantNote(
      data: {
        textnote: $textnote
        activity: $activity
        participants: $participantIds
        youth_center: $youth_center
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_PARTICIPANT_NOTE = gql`
  mutation updateParticipantNote(
    $id: ID!
    $textnote: String!
    $activity: ID
    $participantIds: [ID]
    $youth_center: ID
  ) {
    updateParticipantNote(
      id: $id
      data: {
        textnote: $textnote
        activity: $activity
        participants: $participantIds
        youth_center: $youth_center
      }
    ) {
      data {
        id
        attributes {
          textnote
          participants {
            data {
              id
            }
          }
          activity {
            data {
              id
            }
          }
          youth_center {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PARTICIPANT_NOTE = gql`
  mutation deleteParticipantNote($id: ID!) {
    deleteParticipantNote(id: $id) {
      data {
        id
      }
    }
  }
`;

export const CREATE_SERVICE_POINT = gql`
  mutation createServicePoint(
    $serviceName: String!
    $serviceAddress: String!
    $youth_center: ID
  ) {
    createServicePoint(
      data: {
        name: $serviceName
        address: $serviceAddress
        youth_center: $youth_center
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_SERVICE_POINT = gql`
  mutation updateServicePoint(
    $id: ID!
    $serviceName: String!
    $serviceAddress: String!
    $youth_center: ID
  ) {
    updateServicePoint(
      id: $id
      data: {
        name: $serviceName
        address: $serviceAddress
        youth_center: $youth_center
      }
    ) {
      data {
        id
        attributes {
          name
          address
          youth_center {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SERVICE_POINT = gql`
  mutation deleteServicePoint($id: ID!) {
    deleteServicePoint(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_SERVICE_POINT_YOUTH_CENTER = (youth_center) => gql`
  query GetServicePoints {
    youthCenter(id: ${youth_center}) {
      data {
        id
        attributes {
          service_points {
            data {
              id
              attributes {
                name
                address
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_YOUTHCENTER = gql`
  query getYouthCenterForUser {
    me {
      id
      youth_center {
        data {
          id
          attributes {
            Name
            email
            phonenumber
            Address
            mailingAddress
            secondPhoneNumber
            website
            facebook
            instagram
            twitter
            tikTok
            charityNumber
            NEQ
            incorporationDate
            memberSinceDate
            nameRP
            titleRP
            emailRP
            numOfBoardSeats
            boardContactName
            boardPosition
            phoneBoard
            emailBoard
            preferences
            service_points {
              data {
                id
                attributes {
                  name
                  address
                }
              }
            }
            region {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_YOUTHCENTER_BY_ID = gql`
  query getYouthCenterForUser($id: ID!) {
    youthCenter(id: $id) {
      data {
        id
        attributes {
          Name
          email
          phonenumber
          Address
          mailingAddress
          secondPhoneNumber
          website
          facebook
          instagram
          twitter
          tikTok
          charityNumber
          NEQ
          incorporationDate
          memberSinceDate
          nameRP
          titleRP
          emailRP
          numOfBoardSeats
          boardContactName
          boardPosition
          phoneBoard
          emailBoard
          preferences
          service_points {
            data {
              id
              attributes {
                name
                address
              }
            }
          }
          region {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_YOUTHCENTER = gql`
  mutation updateYouthCenter(
    $id: ID!
    $Name: String!
    $email: String
    $phonenumber: Long
    $Address: String
    $region: ID
    $mailingAddress: String
    $secondPhoneNumber: String
    $website: String
    $facebook: String
    $instagram: String
    $twitter: String
    $tikTok: String
    $charityNumber: String
    $NEQ: String
    $incorporationDate: Date
    $memberSinceDate: Date
    $nameRP: String
    $titleRP: String
    $emailRP: String
    $numOfBoardSeats: Int
    $boardContactName: String
    $boardPosition: String
    $phoneBoard: String
    $emailBoard: String
    $preferences: JSON
  ) {
    updateYouthCenter(
      id: $id
      data: {
        Name: $Name
        email: $email
        phonenumber: $phonenumber
        Address: $Address
        region: $region
        mailingAddress: $mailingAddress
        secondPhoneNumber: $secondPhoneNumber
        website: $website
        facebook: $facebook
        instagram: $instagram
        twitter: $twitter
        tikTok: $tikTok
        charityNumber: $charityNumber
        NEQ: $NEQ
        incorporationDate: $incorporationDate
        memberSinceDate: $memberSinceDate
        nameRP: $nameRP
        titleRP: $titleRP
        emailRP: $emailRP
        numOfBoardSeats: $numOfBoardSeats
        boardContactName: $boardContactName
        boardPosition: $boardPosition
        phoneBoard: $phoneBoard
        emailBoard: $emailBoard
        preferences: $preferences
      }
    ) {
      data {
        id
        attributes {
          Name
          email
          phonenumber
          Address
          mailingAddress
          secondPhoneNumber
          website
          facebook
          instagram
          twitter
          tikTok
          charityNumber
          NEQ
          incorporationDate
          memberSinceDate
          nameRP
          titleRP
          emailRP
          numOfBoardSeats
          boardContactName
          boardPosition
          phoneBoard
          emailBoard
          preferences
          region {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_YOUTHCENTER_PREFERENCES = gql`
  mutation updateYouthCenterPreferences($id: ID!, $preferences: JSON) {
    updateYouthCenter(id: $id, data: { preferences: $preferences }) {
      data {
        id
        attributes {
          preferences
        }
      }
    }
  }
`;

export const PARTICIPANT = gql`
  query getParticipant($id: ID!) {
    participant(id: $id) {
      data {
        id
        attributes {
          firstName
          lastName
          year
          day
          month
          archived
          archivationDate
          messenger_url
          medical_conditions
          school
          school_level
          health_insurance_number
          referring_organisation
          contact_person
          authorization
          first_attendance_date
          last_attendance_date
          last_service_point
          exit_permit
          other_contacts
          files {
            data {
              id
              attributes {
                name
                mime
                url
                previewUrl
              }
            }
          }
          expulsions {
            data {
              id
              attributes {
                start
                end
                reason
              }
            }
          }
          disabilities {
            data {
              id
              attributes {
                name
              }
            }
          }
          sexe
          address
          city
          other
          email
          phoneNumber
          pronoms
          province {
            data {
              id
              attributes {
                name
              }
            }
          }
          postcode
          participant_types {
            data {
              id
              attributes {
                name
                isYearRequired
              }
            }
          }
          attendances(filters: { present: { eq: true } }) {
            data {
              id
              attributes {
                date
                present
                service_point {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          participant_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PARTICIPANT = gql`
  mutation updateParticipant(
    $id: ID!
    $firstName: String!
    $lastName: String
    $month: Int
    $day: Int
    $year: Int
    $address: String
    $city: String
    $other: String
    $email: String
    $phoneNumber: String
    $pronoms: String
    $province: ID
    $postcode: String
    $sexe: ENUM_PARTICIPANT_SEXE
    $participant_types: [ID]
    $disabilities: [ID]
    $participant_tags: [ID]
    $messenger_url: String
    $medical_conditions: String
    $school: String
    $school_level: String
    $health_insurance_number: String
    $referring_organisation: String
    $contact_person: String
    $first_attendance_date: Date
    $exit_permit: Boolean
    $other_contacts: String
    $authorization: Boolean
  ) {
    updateParticipant(
      id: $id
      data: {
        firstName: $firstName
        lastName: $lastName
        month: $month
        day: $day
        year: $year
        sexe: $sexe
        other: $other
        email: $email
        phoneNumber: $phoneNumber
        pronoms: $pronoms
        address: $address
        city: $city
        postcode: $postcode
        province: $province
        participant_types: $participant_types
        disabilities: $disabilities
        participant_tags: $participant_tags
        messenger_url: $messenger_url
        medical_conditions: $medical_conditions
        school: $school
        school_level: $school_level
        health_insurance_number: $health_insurance_number
        referring_organisation: $referring_organisation
        contact_person: $contact_person
        authorization: $authorization
        first_attendance_date: $first_attendance_date
        exit_permit: $exit_permit
        other_contacts: $other_contacts
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          year
          day
          month
          messenger_url
          medical_conditions
          school
          school_level
          health_insurance_number
          referring_organisation
          contact_person
          authorization
          first_attendance_date
          disabilities {
            data {
              id
              attributes {
                name
              }
            }
          }
          sexe
          other
          email
          phoneNumber
          pronoms
          address
          city
          province {
            data {
              id
              attributes {
                name
              }
            }
          }
          postcode
          participant_types {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PARTICIPANT_TAG = gql`
  mutation createParticipantTag($name: String!, $youth_centers: [ID]) {
    createParticipantTag(data: { name: $name, youth_centers: $youth_centers }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const GET_ALL_PARTICIPANT_TAGS = (youth_center) => {
  return gql`
    query getParticipantTags {
      participantTags(filters: {
        youth_centers: {
          id: {
            eq: ${youth_center}
          }
        }
      }) {
        data {
          id
          attributes {
            name
            tooltip
          }
        }
      }
    }
  `;
};

export const MERGE_PARTICIPANT = gql`
  mutation updateParticipant(
    $id: ID!
    $attendances: [ID]
    $participant_notes: [ID]
    $participant_types: [ID]
    $disabilities: [ID]
    $expulsions: [ID]
    $files: [ID]
    $last_attendance_date: Date!
    $last_service_point: Int!
  ) {
    updateParticipant(
      id: $id
      data: {
        attendances: $attendances
        participant_notes: $participant_notes
        participant_types: $participant_types
        disabilities: $disabilities
        expulsions: $expulsions
        files: $files
        last_attendance_date: $last_attendance_date
        last_service_point: $last_service_point
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_PARTICIPANT_ATTACHMENTS = gql`
  mutation updateParticipant($id: ID!, $files: [ID]) {
    updateParticipant(id: $id, data: { files: $files }) {
      data {
        attributes {
          files {
            data {
              id
              attributes {
                name
                mime
                url
                previewUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const ARCHIVE_PARTICIPANT = gql`
  mutation updateParticipant(
    $id: ID!
    $archived: Boolean!
    $archivationDate: DateTime!
  ) {
    updateParticipant(
      id: $id
      data: { archived: $archived, archivationDate: $archivationDate }
    ) {
      data {
        id
        attributes {
          archived
          archivationDate
        }
      }
    }
  }
`;

export const ACTIVITY = (id) => {
  return gql`
    query GetActivity {
      activity(id: ${id}) {
        data {
          id
          attributes {
            date
            title
            start_time
            end_time
            youth
            grand_public
            needs_review
            start_date
            end_date
            virtual_presences_count
            files {
              data {
                id
                attributes {
                  name
                  mime
                  url
                  previewUrl
                }
              }
            }
            activity_involvements {
              data {
                id
                attributes {
                  count
                  activity_theme {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_tag {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_characteristic {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            filled_by
            activity_type {
              data {
                id
                attributes {
                  name
                  showTags
                }
              }
            }
            activity_scenario {
              data {
                id
                attributes {
                  name
                  enable_virtual_presences_count
                }
              }
            }
            service_point {
              data {
                id
                attributes {
                  name
                  address
                }
              }
            }
            public_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            project {
              data {
                id
                attributes {
                  name
                  description
                  youth_center {
              data {
                id
                attributes {
                  Name
                }
              }
            }
            activities {
              data {
                id
                attributes {
                  title
                }
              }
            }
                }
              }
            }
            youth_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            themes {
              data {
                id
                attributes {
                  name
                }
              }
            }
            activity_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            activity_characteristics {
              data {
                id
                attributes {
                  name
                }
              }
            }
            goals {
              data {
                id
                attributes {
                  name
                }
              }
            }
            participants {
              data {
                id
                attributes {
                  firstName
                  lastName
                  participant_types {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            observation
          }
        }
      }
    }
  `;
};

export const ACTIVITY_TYPE = gql`
  query GetActivityType {
    activityTypes {
      data {
        id
        attributes {
          name
          showTags
          tooltip
        }
      }
    }
  }
`;

export const ACTIVITY_SCENARIO = gql`
  query GetActivityScenario {
    activityScenarios {
      data {
        id
        attributes {
          name
          enable_virtual_presences_count
        }
      }
    }
  }
`;

export const PROJECTBYID = (id) => {
  return gql`
    query getProjectById {
      projects(filters: { id: { eq: ${id} } }) {
        data {
          id
          attributes {
            name
            description
            youth_center {
              data {
                id
                attributes {
                  Name
                }
              }
            }
            activities {
              data {
                id
                attributes {
                  title
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const PROJECTS = (youth_center) => {
  return gql`
    query getProjectsByYouthCenter {
      projects(filters: {
        youth_center: {
          id: {
            eq: ${youth_center}
          }
        }
      }) {
        data {
          id
          attributes {
            name
            description
            youth_center {
              data {
                id
                attributes {
                  Name
                }
              }
            }
            activities {
              data {
                id
                attributes {
                  title
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const THEMES = gql`
  query GetThemes {
    themes {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const TAGS = (youth_center) => {
  return gql`
    query GetTAGS {
      activityTags(
        filters: {
          youth_centers: {
            id: {
              eq: ${youth_center}
            }
          },
          soft_deleted: {
            eq: false
          }
        }
      ) {
        data {
          id
          attributes {
            name
            tooltip
          }
        }
      }
    }
  `;
};

export const GETYOUTHTAGS = (youth_center) => gql`
  query GetYouthTags {
    youthTags(
      filters: {
        youth_center: {
          id: {
            eq: ${youth_center}
          }
        }
      }
    ) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const GETPUBLICTAGS = (youth_center) => gql`
  query GetPublicTags {
    publicTags(filters: {
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
    } 
    ) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const GOALS = gql`
  query GetGoals {
    goals {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const PUBLIC_USERS = (youth_center) => gql`
  query GetParticipants {
    participants(filters: {
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
    }) {
      data {
        id
        attributes {
          firstName
          lastName
          archived
          archivationDate
        }
      }
    }
  }
`;

export const ATTENDANCE_USERS = (youth_center) => gql`
  query GetParticipantsForAttendance {
    participants(filters:{
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
    },
    sort: "last_attendance_date:desc"
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          sexe
          archived
          archivationDate
          first_attendance_date
          last_attendance_date
          expulsions {
            data {
              id
              attributes {
                start
                end
                reason
              }
            }
          }
          attendances {
            data {
              id
              attributes {
                date
                present
                youth_center {
                  data {
                    id
                  }
                }
                service_point {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const USERS = (youth_center) => gql`
  query GetParticipants {
    participants(filters: {
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
    }) {
      data {
        id
        attributes {
          firstName
          lastName
          month
          day
          year
          email
          city
          address
          postcode
          archived
          archivationDate
          province {
            data {
              id
              attributes {
                name
              }
            }
          }
          sexe
          youth_center {
            data {
              id
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const PARTICIPANT_LISTING_IN_DATE_RANGE = ({
  youthCenterId,
  startDate,
  endDate,
  participantTypeId,
}) => gql`
  query GetParticipantsListing {
    participants(filters: {
      youth_center: {
        id: {
          eq: ${youthCenterId}
        }
      }
      last_attendance_date: {
        gte: "${startDate}"
        lte: "${endDate}"
      }
      participant_types: {
        id: {
          in: ${participantTypeId}
        }
      }
    }) {
      data {
        id
        attributes {
          firstName
          lastName
          year
          month
          day
          archived
          archivationDate
          address
          city
          email
          first_attendance_date
          last_attendance_date
          last_service_point
          postcode
          sexe
          other
          attendances {
            data {
              id
              attributes {
                date
              }
            }
          }
          participant_types {
            data {
              id
              attributes {
                name
              }
            }
          }
          disabilities {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PARTICIPANT_FILTER_LISTING = gql`
  query GetFilteredParticipants($youthCenterId: ID, $types: [ID]) {
    participants(
      filters: {
        youth_center: { id: { eq: $youthCenterId } }
        participant_types: { id: { in: $types } }
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          year
          month
          day
          archived
          archivationDate
          address
          city
          email
          first_attendance_date
          last_attendance_date
          last_service_point
          postcode
          sexe
          other
          participant_notes {
            data {
              id
              attributes {
                textnote
              }
            }
          }
          disabilities {
            data {
              id
              attributes {
                name
              }
            }
          }
          phoneNumber
          pronoms
        }
      }
    }
  }
`;

export const GET_ATTENDANCES_FOR_DATE = ({ youth_center, date }) => gql`
  query GetAttendancesForDate {
    attendances(filters: {
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
      date: {
        eq: "${date}"
      }
    }) {
      data {
        id
        attributes {
          present
          date
          participant {
            data {
              id
              attributes {
                firstName
                lastName
                year
                month
                day
                archived
              }
            }
          }
          service_point {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_LISTING = (youth_center) => gql`
  query GetUsersListing {
    usersPermissionsUsers(filters: {
      youth_center: {
        id: {
          eq: ${youth_center}
        }
      }
    }) {
      data {
        id
        attributes {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        email
        firstName
        lastName
        requestPasswordChange
        blocked
        role {
          id
          name
          type
        }
        youth_center {
          data {
            id
            attributes {
              Name
              email
              phonenumber
              Address
            }
          }
        }
      }
    }
  }
`;

export const getSingleUser = gql`
  query getUser($userId: ID!) {
    usersPermissionsUser(id: $userId) {
      data {
        id
        attributes {
          email
          firstName
          lastName
          requestPasswordChange
          blocked
          youth_center {
            data {
              id
              attributes {
                Name
                email
                phonenumber
                Address
              }
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($id: ID!, $password: String!) {
    updateUsersPermissionsUser(
      id: $id
      data: { password: $password, requestPasswordChange: false }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $blocked: Boolean
  ) {
    updateUsersPermissionsUser(
      id: $id
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        username: $username
        blocked: $blocked
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          email
          username
          blocked
        }
      }
    }
  }
`;

export const UPDATE_USER_YOUTH_CENTER = gql`
  mutation updateUser($id: ID!, $youthCenter: ID!) {
    updateUsersPermissionsUser(id: $id, data: { youth_center: $youthCenter }) {
      data {
        id
        attributes {
          email
          firstName
          lastName
          requestPasswordChange
          blocked
          role {
            data {
              id
              attributes {
                name
                type
              }
            }
          }
          youth_center {
            data {
              id
              attributes {
                Name
                email
                phonenumber
                Address
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $password: String!
    $youth_center: ID
    $guide_signed: Boolean!
  ) {
    createUsersPermissionsUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        username: $username
        password: $password
        requestPasswordChange: false
        youth_center: $youth_center
        role: 1
        guide_signed: $guide_signed
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        id
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $title: String!
    $youth: Int
    $grand_public: Int
    $date: Date!
    $start_time: Time!
    $end_time: Time!
    $observation: String!
    $activity_type: ID
    $activity_scenario: ID
    $service_point: ID
    $themes: [ID]
    $activity_tags: [ID]
    $public_tags: [ID]
    $youth_tags: [ID]
    $activity_characteristics: [ID]
    $goals: [ID]
    $users: [ID]
    $youth_center: ID
    $filled_by: String
    $needs_review: Boolean
    $files: [ID]
    $start_date: DateTime!
    $end_date: DateTime!
    $virtual_presences_count: Int
  ) {
    createActivity(
      data: {
        title: $title
        youth: $youth
        grand_public: $grand_public
        date: $date
        start_time: $start_time
        end_time: $end_time
        observation: $observation
        activity_type: $activity_type
        activity_scenario: $activity_scenario
        service_point: $service_point
        themes: $themes
        activity_tags: $activity_tags
        public_tags: $public_tags
        youth_tags: $youth_tags
        activity_characteristics: $activity_characteristics
        goals: $goals
        participants: $users
        youth_center: $youth_center
        filled_by: $filled_by
        needs_review: $needs_review
        files: $files
        start_date: $start_date
        end_date: $end_date
        virtual_presences_count: $virtual_presences_count
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const CREATE_ACTIVITY_TAGS = gql`
  mutation createActivityTag($name: String!, $youth_centers: [ID]) {
    createActivityTag(data: { name: $name, youth_centers: $youth_centers }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CREATE_YOUTH_TAGS = gql`
  mutation createYouthTag($name: String!, $tooltip: String, $youth_center: ID) {
    createYouthTag(data: { name: $name, tooltip: $tooltip, youth_center: $youth_center }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const CREATE_PUBLIC_TAGS = gql`
  mutation createPublicTag($name: String!, $tooltip: String, $youth_center: ID) {
    createPublicTag(data: { name: $name, tooltip: $tooltip, youth_center: $youth_center }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($id: ID!) {
    deleteActivity(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity(
    $id: ID!
    $title: String
    $youth: Int
    $grand_public: Int
    $start_time: Time!
    $end_time: Time!
    $observation: String!
    $activity_type: ID
    $activity_scenario: ID
    $service_point: ID
    $themes: [ID]
    $activity_tags: [ID]
    $public_tags: [ID]
    $youth_tags: [ID]
    $activity_characteristics: [ID]
    $goals: [ID]
    $users: [ID]
    $filled_by: String
    $needs_review: Boolean
    $files: [ID]
    $start_date: DateTime!
    $end_date: DateTime!
    $virtual_presences_count: Int
    $project: ID
  ) {
    updateActivity(
      id: $id
      data: {
        title: $title
        youth: $youth
        grand_public: $grand_public
        start_time: $start_time
        end_time: $end_time
        observation: $observation
        activity_scenario: $activity_scenario
        service_point: $service_point
        activity_type: $activity_type
        themes: $themes
        activity_tags: $activity_tags
        public_tags: $public_tags
        youth_tags: $youth_tags
        activity_characteristics: $activity_characteristics
        goals: $goals
        participants: $users
        filled_by: $filled_by
        needs_review: $needs_review
        files: $files
        start_date: $start_date
        end_date: $end_date
        virtual_presences_count: $virtual_presences_count
        project: $project
      }
    ) {
      data {
        id
        attributes {
          title
          youth
          grand_public
          filled_by
          needs_review
          virtual_presences_count
          files {
            data {
              id
              attributes {
                name
                mime
                url
                previewUrl
              }
            }
          }
          project {
            data {
              id
              attributes {
                name
                description
              }
            }
          }
          activity_involvements {
            data {
              id
              attributes {
                count
                activity_theme {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                activity_tag {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                activity_characteristic {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          activity_type {
            data {
              id
              attributes {
                name
                showTags
              }
            }
          }
          activity_scenario {
            data {
              id
              attributes {
                name
              }
            }
          }
          service_point {
            data {
              id
              attributes {
                name
                address
              }
            }
          }
          start_time
          end_time
          observation
          goals {
            data {
              id
              attributes {
                name
              }
            }
          }
          themes {
            data {
              id
              attributes {
                name
              }
            }
          }
          activity_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          youth_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          public_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          activity_characteristics {
            data {
              id
              attributes {
                name
              }
            }
          }
          participants {
            data {
              id
              attributes {
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ACTIVITY_FILES = gql`
  mutation updateActivity($id: ID!, $files: [ID]) {
    updateActivity(id: $id, data: { files: $files }) {
      data {
        id
        attributes {
          files {
            data {
              id
              attributes {
                name
                mime
                url
                previewUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const MARK_ATTENDANCE = gql`
  mutation MarkAttendance(
    $date: Date!
    $present: Boolean
    $participant: ID
    $youth_center: ID
    $service_point: ID
  ) {
    createAttendance(
      data: {
        date: $date
        present: $present
        participant: $participant
        youth_center: $youth_center
        service_point: $service_point
      }
    ) {
      data {
        id
        attributes {
          date
          present
          participant {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ATTENDANCE = gql`
  mutation UpdateAttendance(
    $id: ID!
    $date: Date!
    $present: Boolean
    $participant: ID
    $youth_center: ID
    $service_point: ID
  ) {
    updateAttendance(
      id: $id
      data: {
        date: $date
        present: $present
        participant: $participant
        youth_center: $youth_center
        service_point: $service_point
      }
    ) {
      data {
        id
        attributes {
          date
          present
          participant {
            data {
              id
              attributes {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PARTICIPANT = gql`
  mutation createParticipant(
    $firstName: String!
    $lastName: String
    $month: Int
    $day: Int
    $year: Int
    $address: String
    $city: String
    $other: String
    $email: String
    $phoneNumber: String
    $pronoms: String
    $province: ID
    $postcode: String
    $sexe: ENUM_PARTICIPANT_SEXE
    $participant_types: [ID]
    $disabilities: [ID]
    $youth_center: ID
    $participant_tags: [ID]
    $messenger_url: String
    $medical_conditions: String
    $school: String
    $school_level: String
    $health_insurance_number: String
    $referring_organisation: String
    $contact_person: String
    $first_attendance_date: Date
    $exit_permit: Boolean
    $other_contacts: String
    $authorization: Boolean
  ) {
    createParticipant(
      data: {
        firstName: $firstName
        lastName: $lastName
        month: $month
        day: $day
        year: $year
        sexe: $sexe
        address: $address
        city: $city
        other: $other
        email: $email
        phoneNumber: $phoneNumber
        pronoms: $pronoms
        postcode: $postcode
        province: $province
        participant_types: $participant_types
        disabilities: $disabilities
        youth_center: $youth_center
        participant_tags: $participant_tags
        messenger_url: $messenger_url
        medical_conditions: $medical_conditions
        school: $school
        school_level: $school_level
        health_insurance_number: $health_insurance_number
        referring_organisation: $referring_organisation
        contact_person: $contact_person
        first_attendance_date: $first_attendance_date
        exit_permit: $exit_permit
        other_contacts: $other_contacts
        authorization: $authorization
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          month
          day
          year
          sexe
          address
          city
          other
          email
          phoneNumber
          pronoms
          postcode
          first_attendance_date
          exit_permit
          other_contacts
          authorization
          province {
            data {
              id
              attributes {
                name
              }
            }
          }
          disabilities {
            data {
              id
            }
          }
          attendances {
            data {
              id
              attributes {
                date
                present
                youth_center {
                  data {
                    id
                  }
                }
              }
            }
          }
          youth_center {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const PROVINCE = gql`
  query GetProvince {
    provinces {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const PARTICIPANT_TYPES = gql`
  query GetParticipantTypes {
    participantTypes {
      data {
        id
        attributes {
          name
          isYearRequired
          tooltip
        }
      }
    }
  }
`;

export const DISABILITIES = gql`
  query GetDisabilities {
    disabilities {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const ACTIVITY_CHARACTERISTICS = gql`
  query GetCharacteristics {
    activityCharacteristics {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const GET_ATTENDANCE_DASHBOARD = (startDate, endDate, youth_center) => {
  return gql`
    query getAttendances {
      attendances(filters:{
        date: {
          gte: "${startDate}"
          lte:  "${endDate}"
        }
        present: {
          eq: true
        }
        youth_center: {
          id: {
            eq: ${youth_center}
          }
        }
      }, pagination: { limit: 1000 }) {
        data {
          id
          attributes {
            date
            participant {
              data {
                id
                attributes {
                  firstName
                  lastName
                  first_attendance_date
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_ACTIVITIES_DASHBOARD = (startDate, endDate, youth_center) => {
  return gql`
    query getActivties {
      activities(filters: {
        date: {
          gte: "${startDate}",
          lte: "${endDate}",
        }
        youth_center: {
          id: {
            eq: ${youth_center}
          }
        }
      }, pagination: { limit: 1000 }){
        data {
          id
          attributes {
            title
            date
            start_time
            end_time
            needs_review
            start_date
            end_date
            activity_involvements {
              data {
                id
                attributes {
                  count
                  activity_theme {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_tag {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  activity_characteristic {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            activity_type {
              data {
                id
                attributes {
                  name
                  showTags
                }
              }
            }
            activity_scenario {
              data {
                id
                attributes {
                  name
                }
              }
            }
            service_point {
              data {
                id
                attributes {
                  name
                  address
                }
              }
            }
            activity_tags{
              data {
                id
                attributes {
                  name
                }
              }
            }
            themes {
              data {
                id
                attributes {
                  name
                }
              }
            }
            goals {
              data {
                id
                attributes {
                  name
                }
              }
            }
            participants {
              data {
                id
                attributes {
                  firstName
                  lastName
                }
              }
            }
            participant_notes {
              data {
                id
              }
            }
            observation
            youth
            youth_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
            grand_public
            public_tags {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_REGIONS = gql`
  query getRegion {
    regions(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const UPDATE_ACTIVITY_INVOLVEMENT = gql`
  mutation updateActivityInvolvement($id: ID!, $count: Int!) {
    updateActivityInvolvement(id: $id, data: { count: $count }) {
      data {
        id
        attributes {
          count
        }
      }
    }
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      firstName
      lastName
      requestPasswordChange
      blocked
      role {
        id
        name
        type
      }
      youth_center {
        data {
          id
          attributes {
            Name
            email
            phonenumber
            Address
          }
        }
      }
    }
  }
`;

export const GET_YOUTH_CENTERS = gql`
  query getYouthCenterForUser {
    youthCenters(pagination: { limit: 1000 }) {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;

export const CREATE_EXPULSION = gql`
  mutation createExpulsion(
    $startDate: Date!
    $endDate: Date!
    $participant: ID!
    $reason: String
  ) {
    createExpulsion(
      data: {
        start: $startDate
        end: $endDate
        participant: $participant
        reason: $reason
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_EXPULSION = gql`
  mutation updateExpulsion(
    $id: ID!
    $start: Date!
    $end: Date!
    $participant: ID!
    $reason: String
  ) {
    updateExpulsion(
      id: $id
      data: {
        start: $start
        end: $end
        participant: $participant
        reason: $reason
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_EXPULSION = gql`
  mutation deleteExpulsion($id: ID!) {
    deleteExpulsion(id: $id) {
      data {
        id
      }
    }
  }
`;

export const ETIQUETTES_LISTING = (youth_center) => gql`
  query GetActivityTagsListing {
    activityTags(filters:{
      youth_centers: {
        id: {
          eq: ${youth_center}
        }
      }
      soft_deleted: {
        eq: false
      }
    }) {
      data {
        id
        attributes {
          name
          is_global
          tooltip
        }
      }
    }
  }
`;

export const GLOBAL_ETIQUETTES_LISTING = () => gql`
  query GetActivityTagsListing {
    activityTags(
      filters: { is_global: { eq: true }, soft_deleted: { eq: false } }
    ) {
      data {
        id
        attributes {
          name
          is_global
          tooltip
        }
      }
    }
  }
`;

export const CREATE_ETIQUETTE = gql`
  mutation createEtiquette($name: String!, $tooltip: String, $youth_centers: [ID]) {
    createActivityTag(data: { name: $name, tooltip: $tooltip, youth_centers: $youth_centers }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const UPDATE_ETIQUETTE = gql`
  mutation updateEtiquette($id: ID!, $name: String!, $tooltip: String) {
    updateActivityTag(id: $id, data: { name: $name, tooltip: $tooltip }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const DELETE_ETIQUETTE = gql`
  mutation deleteEtiquette($id: ID!) {
    deleteActivityTag(id: $id) {
      data {
        id
      }
    }
  }
`;

// UPDATE_YOUTH_TAGS

export const UPDATE_YOUTH_TAG = gql`
  mutation updateYouthTag($id: ID!, $name: String!, $tooltip: String) {
    updateYouthTag(id: $id, data: { name: $name, tooltip: $tooltip }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const DELETE_YOUTH_TAG = gql`
  mutation deleteYouthTag($id: ID!) {
    deleteYouthTag(id: $id) {
      data {
        id
      }
    }
  }
`;

// UPDATE_PUBLIC_TAGS
export const UPDATE_PUBLIC_TAG = gql`
  mutation updatePublicTag($id: ID!, $name: String!, $tooltip: String) {
    updatePublicTag(id: $id, data: { name: $name, tooltip: $tooltip }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const DELETE_PUBLIC_TAG = gql`
  mutation deleteYouthTag($id: ID!) {
    deletePublicTag(id: $id) {
      data {
        id
      }
    }
  }
`;

// UPDATE_PARTICIPANT_TAGS
export const UPDATE_PARTICIPANT_TAG = gql`
  mutation updateParticipantTag($id: ID!, $name: String!, $tooltip: String) {
    updateParticipantTag(id: $id, data: { name: $name, tooltip: $tooltip }) {
      data {
        id
        attributes {
          name
          tooltip
        }
      }
    }
  }
`;

export const DELETE_PARTICIPANT_TAG = gql`
  mutation deleteParticipantTag($id: ID!) {
    deleteParticipantTag(id: $id) {
      data {
        id
      }
    }
  }
`;

// Projects

export const CREATE_PROJECT = gql`
  mutation createProject(
    $name: String!
    $description: String
    $youth_center: ID
  ) {
    createProject(
      data: {
        name: $name
        description: $description
        youth_center: $youth_center
      }
    ) {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: ID!, $name: String!, $description: String) {
    updateProject(id: $id, data: { name: $name, description: $description }) {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_FEATURE_FLAGS = gql`
 query getFeatureFlags {
  featureFlags {
    data {
      id
      attributes {
        name
        enabled
      }
    }
 }
}
`;
