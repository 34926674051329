// @flow
import React from "react";
import ProjectsListingView from "./ProjectsListingView";

export default class ProjectsListingController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <ProjectsListingView {...this.props} />;
  }
}
