// @flow
import React from "react";
import PreferencesView from "./PreferencesView";

export default class PreferencesController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <PreferencesView {...this.props} />;
  }
}
