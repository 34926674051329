import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Header } from "../../components";
import {
  GET_YOUTHCENTER_BY_ID,
  GET_FEATURE_FLAGS,
  UPDATE_YOUTHCENTER_PREFERENCES,
} from "../../graphql";
import { AppStyles } from "../../theme";
import { css } from "aphrodite";
import { defaultFieldPreferences } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  cardWrapper: {
    boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",
    padding: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "#5cbdaf",
    color: "white",
    "&:hover": {
      backgroundColor: "#4aa193",
    },
  },
  draggableItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: "#f4f4f4",
    border: "1px solid #ddd",
    borderRadius: 4,
  },
  dragHandle: {
    marginRight: theme.spacing(2),
    cursor: "grab",
  },
}));

const PreferencesView = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  const [tab, setTab] = useState("activity");
  const [fields, setFields] = useState([]);
  const [fieldPreferences, setFieldPreferences] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const { data: featureFlagsData } = useQuery(GET_FEATURE_FLAGS);
  const isPreferencesEnabled = featureFlagsData?.featureFlags?.data?.some(
    (flag) => flag.attributes.name === "Preferences" && flag.attributes.enabled
  );

  const { data, loading, error, refetch } = useQuery(GET_YOUTHCENTER_BY_ID, {
    variables: { id: user.youth_center.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const preferences = data?.youthCenter?.data?.attributes?.preferences;
      if (preferences) {
        setFieldPreferences(preferences);
        setFields(
          preferences[
            tab === "activity" ? "activityFields" : "participantFields"
          ]
        );
      } else {
        setFieldPreferences(defaultFieldPreferences);
      }
    },
  });

  const [updateYouthCenterPreferences] = useMutation(
    UPDATE_YOUTHCENTER_PREFERENCES
  );

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setFields(
      newValue === "activity"
        ? fieldPreferences.activityFields
        : fieldPreferences.participantFields
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFields = Array.from(fields);
    const [movedItem] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, movedItem);

    setFields(reorderedFields);
  };

  const toggleFieldVisibility = (fieldId) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, visible: !field.visible } : field
      )
    );
  };

  const handleSave = async () => {
    try {
      const updatedPreferences = {
        ...fieldPreferences,
        [tab === "activity" ? "activityFields" : "participantFields"]: fields,
      };

      await updateYouthCenterPreferences({
        variables: {
          id: user.youth_center.id,
          preferences: updatedPreferences,
        },
      });

      await refetch();
      setSnackbar({
        open: true,
        message: "Paramètres sauvegardés avec succès !",
        severity: "success",
      });
    } catch (err) {
      console.error("Erreur lors de la sauvegarde :", err);
      setSnackbar({
        open: true,
        message: "Une erreur s'est produite lors de la sauvegarde.",
        severity: "error",
      });
    }
  };

  const closeSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur lors du chargement des données.</div>;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeSnackbar}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Header />
{isPreferencesEnabled && (
      <div className={classes.root}>
        <Container maxWidth="lg" className={css(AppStyles.marginTop128)}>
          <Card className={classes.cardWrapper}>
            <CardContent>
              <Typography variant="h4" className={classes.titleone}>
                Préférences
              </Typography>
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Activité" value="activity" />
                <Tab label="Participant" value="participant" />
              </Tabs>
              <Box mt={2}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <List
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {fields.map((field, index) => (
                          <Draggable
                            key={field.id}
                            draggableId={field.id}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={classes.draggableItem}
                              >
                                <div
                                  {...provided.dragHandleProps}
                                  className={classes.dragHandle}
                                >
                                  <DragHandleIcon />
                                </div>
                                <ListItemText primary={field.label} />
                                {!field.isMandatory && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() =>
                                        toggleFieldVisibility(field.id)
                                      }
                                    >
                                      {field.visible ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={handleSave}
              >
                Sauvegarder
              </Button>
            </CardContent>
          </Card>
        </Container>
      </div>
      )}
    </>
  );
};

export default PreferencesView;
