import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Add } from "@material-ui/icons";

import { Colors } from "../../theme";
import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useQuery } from "@apollo/client";
import {
  CREATE_ETIQUETTE,
  DELETE_ETIQUETTE,
  UPDATE_ETIQUETTE,
  GLOBAL_ETIQUETTES_LISTING,
  CREATE_YOUTH_TAGS,
  CREATE_PUBLIC_TAGS,
  CREATE_PARTICIPANT_TAG,
  UPDATE_YOUTH_TAG,
  DELETE_YOUTH_TAG,
  UPDATE_PUBLIC_TAG,
  DELETE_PUBLIC_TAG,
  UPDATE_PARTICIPANT_TAG,
  DELETE_PARTICIPANT_TAG,
  PROJECTS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
} from "../../graphql";
import styles from "./ProjectsListingStyles";
import { Header } from "../../components";
import _ from "lodash";
import { useSelector } from "react-redux";
import { ProjectsModal } from "./ProjectsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    marginLeft: "auto",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProjectsListingView(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const classes = useStyles();

  const [tableData, setTableData] = useState(() => []);
  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [modalProject, setModalProject] = useState(null);
  const [modalActions, setModalActions] = useState({
    CREATE: CREATE_PROJECT,
    UPDATE: UPDATE_PROJECT,
    DELETE: DELETE_PROJECT,
  });

  const { refetch } = useQuery(PROJECTS(user.youth_center.id), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const mappedProjects = data.projects.data.map((p) => ({
        id: p.id,
        ...p.attributes,
      }));

      setTableData(mappedProjects);
    },
  });

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const formSubmitHandler = () => {
    refetch()
      .then((resp) => {
        const mappedProjects = resp.data.projects.data.map((p) => ({
          id: p.id,
          ...p.attributes,
        }));
        setTableData(mappedProjects);
      })
      .catch((err) => console.error("Refetch failed:", err));
  };

  const formCloseHandler = () => {
    setOpenModal(false);
    setModalProject(null);
    refetch()
      .then((resp) => {
        const mappedProjects = resp.data.projects.data.map((p) => ({
          id: p.id,
          ...p.attributes,
        }));
        setTableData(mappedProjects);
      })
      .catch((err) => console.error("Refetch failed:", err));
  };

  const columns = [
    {
      key: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      key: "name",
      field: "name",
      headerName: "Nom",
      minWidth: 200,
      renderCell: (param) => {
        return <div>{`${param.row?.name}`}</div>;
      },
    },
    {
      key: "description",
      field: "description",
      headerName: "Description",
      minWidth: 400,
      renderCell: (param) => {
        return <div>{`${param.row?.description}`}</div>;
      },
    },
    {
      key: "activities-count",
      field: "activities",
      headerName: "Nombre d'activités",
      minWidth: 200,
      renderCell: (param) => {
        return <div>{`${param.row?.activities.data.length}`}</div>;
      },
    },
  ];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Header />
      <>
        <div className={classes.root}>
          <Container maxWidth="lg" className={css(AppStyles.marginTop128)}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={11}>
                    <Typography variant="h4" className={classes.titleone}>
                      Projets
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1} alignItems="flex-end">
                    <Button
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className={classes.addBtn}
                    >
                      <Add />
                    </Button>
                  </Grid>
                </Grid>

                <div className={css(styles.TableWrpper)}>
                  <DataGrid
                    className={`usersTable`}
                    localeText={frFR.props.MuiDataGrid.localeText}
                    rows={tableData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 50, 100]}
                    isRowSelectable={false}
                    disableSelectionOnClick={true}
                    disableColumnMenu={true}
                    sortingOrder={["desc", "asc", null]}
                    onRowClick={(param) => {
                      setModalProject(param.row);
                      setOpenModal(true);
                    }}
                    getRowClassName={(params) => {
                      if (params.row.is_global) {
                        return "global-etiquette-disabled";
                      }
                    }}
                  />
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
      <ProjectsModal
        open={openModal}
        modalActions={modalActions}
        apiEndpoint={null}
        extProject={modalProject}
        handleClose={formCloseHandler}
        formSubmitCallback={formSubmitHandler}
      />
    </div>
  );
}
