import React from "react";
import { SvgIcon } from '@material-ui/core'

const ExcelIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path fill="#231F20" d="M297.941 48H96v416h320V166.059z" />
      <path fill="#FFF" d="M392 440H120V72h168l104 104z" />
      <path fill="#6D6E71" d="M288 176V72l104 104z" />
      <path fill="#E6E7E8" d="m288 176 104 104V176z" />
      <path fill="#231F20" d="M48 176h72v240H48z" />
      <linearGradient id="a" x1="260.916" x2="123.347" y1="296" y2="296" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#e6e7e8" />
      </linearGradient>
      <path fill="url(#a)" d="M120 176h144v240H120z" />
      <path fill="#2FA860" d="M72 200h192v192H72z" />
      <path fill="#E6E7E8" d="M264 248h24v16h-24zM264 288h24v16h-24zM264 328h24v16h-24zM264 368h24v16h-24zM264 208h24v16h-24zM304 248h24v16h-24zM304 288h24v16h-24zM304 328h24v16h-24zM304 368h24v16h-24zM304 208h24v16h-24z" />
      <path fill="#FFF" d="M224 240h-32l-24 33.6-24-33.6h-32l40 56-40 56h32l24-33.6 24 33.6h32l-40-56z" />
      <path fill="#D4D6D8" d="m320 208 8 8v-8z" />
    </SvgIcon>
  );
};

export default ExcelIcon;
