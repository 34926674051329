export const SAGA_ALERT_TIMEOUT = 500;

export const DATE_FORMAT1 = "Do MMMM YYYY, HH:mm";
export const DATE_FORMAT2 = "Do MMM";
export const DATE_FORMAT3 = "Do MMM YYYY";
export const DATE_FORMAT11 = "dddd DD MMMM YYYY";
export const DATE_FORMAT12 = "dddd DD MMMM YYYY HH:mm";
export const DATE_TIME_FORMAT2 = "MMM D HH A";
export const DATE_TIME_FORMAT7 = "HH:mm (DD MMM)";
export const STRAPI_DATE_FORMAT = "YYYY-MM-DD";
export const STRAPI_TIME_FORMAT = "HH:mm:ss";

export const TIME_FORMAT1 = "HH:mm";
export const TIME_FORMAT2 = "H [h] : mm [min]";
export const TIME_FORMAT3 = "hh A";
export const TIME_DAY_FORMAT3 = "ddd - HH:mm";

export const INTERNET_ERROR = "Please connect to the working internet";
export const SOMETHING_WRONG = "Something went wrong";
export const IS_REQUIRED_ERROR = "This field is required";
export const FAILED_TO_FETCH = "Failed to fetch, try to refresh the page";
export const FORM_VALIDATION_FAILED =
  "La validation du formulaire a échoué. Veuillez vérifier vos entrées.";

export const DEV_ENV = "dev";

export const MESSAGE_TYPES = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
};

export const JOB_STATUS_TYPES = {
  BOOKED: { title: "Booked", type: "Booked" },
  FINDINGDRIVER: { title: "Finding Driver", type: "FindingDriver" },
  CONFIRMED: { title: "Confirmed", type: "Confirmed" },
  COMPLETE: { title: "Complete", type: "Complete" },
  CANCELLED: { title: "Cancelled", type: "Cancelled" },
};

export const BACKEND_URI =
  process.env.REACT_APP_BACKEND_URI ||
  "https://youth-center-adminarea.herokuapp.com";
export const GRAPHQL_URI = `${BACKEND_URI}/graphql`;
export const ADMIN_BASE_URI = `${BACKEND_URI}/admin`;

export const INFO_BASE_URI = "https://sites.google.com/rmjq.org/bdd";

export const ROUTES = {
  DASHBOARD: "/",
  YOUTH_CENTERS: "/youth-centers",
  ACTIVITIES: "/activities",
  DAY: "/day",
  PARTICIPANTS: "/participants",
  PARTICIPANT_NOTES: "/participant-notes",
  RESET_PASSWORD: "/reset-password",
  PRICING: "/pricing",
  FREE_TRIAL: "/free-trial",
  BESPOKE_LOGISTICS: "/products/bespoke-logistics",
  BOOKING_CONFIRM: "/dashboard/booking-confirm",
  BOOKING_THANK_YOU: "/dashboard/thank-you",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
  BOOKINGS: "/dashboard/bookings",
  NEW_BOOKING: "/dashboard/new-booking",
  DRIVER: "/dashboard/driver",
  BOOKING: "/dashboard/booking",
  BOOKING_DETAIL: "/dashboard/booking-detail",
  BOOKING_LIVE: "/dashboard/bookings/live",
  PAGE_NOT_FOUND: "/PageNotFound",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change-password",
  SIGN_UP: "/dashboard/register",
  PUBLIC_TRACKING: "/tracking",
  DRIVER_LANDING_PAGE: "/driver",
  SETTING: "/dashboard/setting",
  DELIVERY_MANAGEMENT: "/dashboard/delivery-management",
  MOBILE_APP_NAVIGATOR: "/app",
  TRACKING: "/track",
  USERS: "/users",
  NEW_USER: "/new-user",
  FORBIDDEN: "/forbidden",
  ETIQUETTES: "/etiquettes",
  PROJECTS: "/projects",
  PREFERENCES: "/preferences",
};

export const USER_PERMISSIONS = {
  RMJQ: "RMJQ",
  MDJ_TEAM: "MDJ team",
  MDJ_DIRECTOR: "MDJ director",
  ADMINISTRATOR: "Administrator",
  ADMINISTRATOR_LIMITED: "AdministratorLimited",
};

export const INVALID_TITLE = "Titre invalide";
export const INVALID_ACTIVITY_TYPE = "Type d'activité invalide";
export const INVALID_ACTIVITY_SCENARIO =
  "Veuillez indiquer si l'activité est en présentiel ou en virtuel";
export const INVALID_THEMES = "Thèmes invalides";
export const INVALID_TAGS = "Étiquettes invalides";
export const INVALID_GOALS = "Objectifs invalides";
export const INVALID_PARTICIPANTS = "Participants invalides";
export const INVALID_OBSERVATION = "Observation invalide";
export const INVALID_CHARACTERISTICS = "Caractéristiques invalides";
export const INVALID_NOTE_INFO = "Notes incomplètes ou invalides";
export const INVALID_SERVICE_INFO =
  "Informations du point de service incomplètes ou invalides";
export const INVALID_START_DATE = "Heure de début invalide";
export const INVALID_END_DATE = "Heure de fin invalide";
export const INVALID_YOUTH_COUNT = "Nombre de jeunes invalides";
export const INVALID_PUBLIC_COUNT = "Nombre public non valide";

// ** User Form Field Errors**
export const INVALID_FIRSTNAME = "Prénom invalide";
export const INVALID_LASTNAME = "Nom de famille invalide";
export const INVALID_EMAIL = "Courriel invalide";
export const INVALID_PHONE = "Téléphone invalide";
export const INVALID_DOB = "Date de naissance invalide";
export const INVALID_GENDER = "Genre invalide";
export const INVALID_ADDRESS = "Adresse invalide";
export const INVALID_POSTALCODE = "Code postal invalide";
export const INVALID_CITY = "Ville invalide";
export const INVALID_PROVINCE = "Province invalide";
export const INVALID_PARTICIPANTS_TYPE = "Participants invalide";
export const INVALID_DISABILITY = "Veuillez en choisir un";
export const INVALID_PROJECT = "Projet invalide";

// ** Youth center Form Field Errors**
export const INVALID_YOUTHCENTER_NAME = "Nom invalide";
export const INVALID_YOUTHCENTER_EMAIL = "Courriel invalide";
export const INVALID_YOUTHCENTER_PHONENUM = "Numéro de téléphone invalide";
export const INVALID_YOUTHCENTER_ADDRESS = "Adresse invalide";

// Initial (setted by default for each Youth-Center fields for Preferences)
const initialFieldsActivity = [
  { id: "1", label: "Titre", visible: true, isMandatory: true, order: 1 },
  {
    id: "2",
    label: "Type d'activité",
    visible: true,
    isMandatory: true,
    order: 2,
  },
  {
    id: "3",
    label: "Heure de début",
    visible: true,
    isMandatory: true,
    order: 3,
  },
  {
    id: "4",
    label: "Heure de fin",
    visible: true,
    isMandatory: true,
    order: 4,
  },
  {
    id: "5",
    label: "Date de début",
    visible: true,
    isMandatory: true,
    order: 5,
  },
  {
    id: "6",
    label: "Date de fin",
    visible: true,
    isMandatory: true,
    order: 6,
  },
  { id: "7", label: "Mode", visible: true, isMandatory: true, order: 7 },
  { id: "8", label: "Thèmes", visible: true, isMandatory: false, order: 8 },
  { id: "9", label: "Objectifs", visible: true, isMandatory: false, order: 9 },
  {
    id: "10",
    label: "Participants",
    visible: true,
    isMandatory: true,
    order: 10,
  },
  {
    id: "11",
    label: "Étiquettes",
    visible: true,
    isMandatory: false,
    order: 11,
  },
];

const initialFieldsParticipant = [
  { id: "12", label: "Prénom", visible: true, isMandatory: false, order: 1 },
  {
    id: "13",
    label: "Nom de familie",
    visible: true,
    isMandatory: false,
    order: 2,
  },
  { id: "14", label: "E-mail", visible: true, isMandatory: false, order: 3 },
  { id: "15", label: "Genre", visible: true, isMandatory: false, order: 4 },
  { id: "16", label: "Pronoms", visible: true, isMandatory: false, order: 5 },
  { id: "17", label: "Courriel", visible: true, isMandatory: false, order: 6 },
  {
    id: "18",
    label: "Téléphone",
    visible: true,
    isMandatory: false,
    order: 7,
  },
  { id: "19", label: "Adresse", visible: true, isMandatory: false, order: 8 },
  { id: "20", label: "Ville", visible: true, isMandatory: false, order: 9 },
  {
    id: "21",
    label: "Province",
    visible: true,
    isMandatory: false,
    order: 10,
  },
  {
    id: "22",
    label: "Code Postal",
    visible: true,
    isMandatory: false,
    order: 11,
  },
  {
    id: "23",
    label: "Adresse Messenger",
    visible: true,
    isMandatory: false,
    order: 12,
  },
  { id: "24", label: "Contact", visible: true, isMandatory: false, order: 13 },
  { id: "25", label: "École", visible: true, isMandatory: false, order: 14 },
  {
    id: "26",
    label: "Niveau Scolaire",
    visible: true,
    isMandatory: false,
    order: 15,
  },
  {
    id: "27",
    label: "Numéro d’assurance maladie",
    visible: true,
    isMandatory: false,
    order: 16,
  },
  {
    id: "28",
    label: "Organisme de référence",
    visible: true,
    isMandatory: false,
    order: 17,
  },
  {
    id: "29",
    label: "Allergies et conditions médicales",
    visible: true,
    isMandatory: false,
    order: 18,
  },
  {
    id: "30",
    label: "Types de participants",
    visible: true,
    isMandatory: false,
    order: 19,
  },
  {
    id: "31",
    label: "Étiquettes",
    visible: true,
    isMandatory: false,
    order: 20,
  },
];

export { initialFieldsActivity, initialFieldsParticipant };

// default preferences for Youth-Centre

const defaultFieldPreferences = {
  activityFields: [
    {
      id: "1",
      label: "Titre",
      order: 1,
      visible: true,
      isMandatory: true,
    },
    {
      id: "2",
      label: "Type d'activité",
      order: 2,
      visible: true,
      isMandatory: true,
    },
    {
      id: "3",
      label: "Heure de début",
      order: 3,
      visible: true,
      isMandatory: true,
    },
    {
      id: "4",
      label: "Heure de fin",
      order: 4,
      visible: true,
      isMandatory: true,
    },
    {
      id: "5",
      label: "Date de début",
      order: 5,
      visible: true,
      isMandatory: true,
    },
    {
      id: "6",
      label: "Date de fin",
      order: 6,
      visible: true,
      isMandatory: true,
    },
    {
      id: "7",
      label: "Mode",
      order: 7,
      visible: true,
      isMandatory: true,
    },
    {
      id: "8",
      label: "Thèmes",
      order: 8,
      visible: true,
      isMandatory: false,
    },
    {
      id: "9",
      label: "Objectifs",
      order: 9,
      visible: true,
      isMandatory: false,
    },
    {
      id: "10",
      label: "Participants",
      order: 10,
      visible: true,
      isMandatory: true,
    },
    {
      id: "11",
      label: "Étiquettes",
      order: 11,
      visible: true,
      isMandatory: false,
    },
  ],
  participantFields: [
    {
      id: "12",
      label: "Prénom",
      order: 1,
      visible: true,
      isMandatory: false,
    },
    {
      id: "13",
      label: "Nom de familie",
      order: 2,
      visible: true,
      isMandatory: false,
    },
    {
      id: "14",
      label: "E-mail",
      order: 3,
      visible: true,
      isMandatory: false,
    },
    {
      id: "15",
      label: "Genre",
      order: 4,
      visible: true,
      isMandatory: false,
    },
    {
      id: "16",
      label: "Pronoms",
      order: 5,
      visible: true,
      isMandatory: false,
    },
    {
      id: "17",
      label: "Courriel",
      order: 6,
      visible: true,
      isMandatory: false,
    },
    {
      id: "18",
      label: "Téléphone",
      order: 7,
      visible: true,
      isMandatory: false,
    },
    {
      id: "19",
      label: "Adresse",
      order: 8,
      visible: true,
      isMandatory: false,
    },
    {
      id: "20",
      label: "Ville",
      order: 9,
      visible: true,
      isMandatory: false,
    },
    {
      id: "21",
      label: "Province",
      order: 10,
      visible: true,
      isMandatory: false,
    },
    {
      id: "22",
      label: "Code Postal",
      order: 11,
      visible: true,
      isMandatory: false,
    },
    {
      id: "23",
      label: "Adresse Messenger",
      order: 12,
      visible: true,
      isMandatory: false,
    },
    {
      id: "24",
      label: "Contact",
      order: 13,
      visible: true,
      isMandatory: false,
    },
    {
      id: "25",
      label: "École",
      order: 14,
      visible: true,
      isMandatory: false,
    },
    {
      id: "26",
      label: "Niveau Scolaire",
      order: 15,
      visible: true,
      isMandatory: false,
    },
    {
      id: "27",
      label: "Numéro d’assurance maladie",
      order: 16,
      visible: true,
      isMandatory: false,
    },
    {
      id: "28",
      label: "Organisme de référence",
      order: 17,
      visible: true,
      isMandatory: false,
    },
    {
      id: "29",
      label: "Allergies et conditions médicales",
      order: 18,
      visible: true,
      isMandatory: false,
    },
    {
      id: "30",
      label: "Types de participants",
      order: 19,
      visible: true,
      isMandatory: false,
    },
    {
      id: "31",
      label: "Étiquettes",
      order: 20,
      visible: true,
      isMandatory: false,
    },
  ],
};

export { defaultFieldPreferences };
