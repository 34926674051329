import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Box,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";

import ActionButton from "../../components/ActionButton";
import styles from "../../components/YouthCenterForm/YouthCenterFormStyles";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "500px",
    margin: "0 20px",
  },
}));

export const ProjectsModal = (props) => {
  const classes = useStyles();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const user = useSelector((state) => {
    return state.user;
  });

  const {
    open,
    handleClose: closeCallback,
    extProject,
    modalActions,
    formSubmitCallback,
  } = props;

  const [project, setProject] = useState({ name: "", description: "" });
  const [projectTitle, setProjectTitle] = useState(project?.name || "");
  const [projectDescription, setProjectDescription] = useState(
    project?.description || ""
  );
  const [fromErr, setFormErr] = useState("");

  const handleClose = () => {
    if (closeCallback) {
      closeCallback();
    }

    if (formSubmitCallback) {
      formSubmitCallback();
    }

    setTimeout(() => {
      setProject("");
    }, 200);
  };

  const [
    createProject,
    { loading: loadingCreateProject, error: errCreateProject },
  ] = useMutation(modalActions.CREATE, {
    onCompleted: handleClose,
  });

  const [
    updateProject,
    { loading: loadingUpdateProject, error: errUpdateProject },
  ] = useMutation(modalActions.UPDATE, {
    onCompleted: handleClose,
  });

  const [
    deleteProject,
    { loading: loadingDeleteProject, error: errDeleteProject },
  ] = useMutation(modalActions.DELETE, {
    onCompleted: handleClose,
  });

  useEffect(() => {
    if (extProject) {
      setProject(extProject);
      setProjectTitle(extProject.name);
      setProjectDescription(extProject.description);
    }
  }, [extProject]);

  const validateForm = () => {
    let isValid = true;

    if (projectTitle.length < 1) {
      setFormErr("Le nom de l'project est requis");
      return false;
    }

    return isValid;
  };

  const handleCreateProject = () => {
    if (validateForm()) {
      createProject({
        variables: {
          name: projectTitle,
          description: projectDescription,
          youth_center: user.youth_center.id,
        },
      });
    }
  };

  const handleUpdateProject = () => {
    if (validateForm()) {
      updateProject({
        variables: {
          id: project.id,
          name: projectTitle,
          description: projectDescription,
        },
      });
    }
  };

  const handleDeleteProject = () => {
    deleteProject({
      variables: {
        id: project.id,
      },
    });
    setOpenRemoveModal(false);
  };

  const renderOperationsErrors = () => {
    if (errCreateProject || errUpdateProject || errDeleteProject) {
      console.error("errCreateProject", errCreateProject);
      console.error("errUpdateProject", errUpdateProject);
      console.error("errDeleteProject", errDeleteProject);

      return (
        <Grid item xs={12}>
          <span className={`${css(AppStyles.formError)}`}>
            Une erreur s'est produite lors de la mise à jour de l'étiquette.
          </span>
        </Grid>
      );
    }

    if (fromErr) {
      return (
        <Grid item xs={12}>
          <span className={`${css(AppStyles.formError)}`}>{fromErr}</span>
        </Grid>
      );
    }

    return null;
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              {Boolean(extProject)
                ? `Modifier le projet "${project.name}"`
                : "Nouveau projet"}
            </h2>
            <form className={classes.root} noValidate autoComplete="off">
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    label="Titre"
                    fullWidth
                    value={projectTitle}
                    onChange={(e) => {
                      setProjectTitle(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        extProject
                          ? handleUpdateProject()
                          : handleCreateProject();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    value={projectDescription}
                    onChange={(e) => {
                      setProjectDescription(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && projectTitle) {
                        e.preventDefault();
                        extProject
                          ? handleUpdateProject()
                          : handleCreateProject();
                      }
                    }}
                  />
                </Grid>

                {renderOperationsErrors()}

                <Grid item xs={12} md={6}>
                  {extProject ? (
                    <ActionButton
                      className={css(styles.deleteBtn)}
                      title="Supprimer"
                      isLoading={loadingDeleteProject}
                      onClick={() => setOpenRemoveModal(true)}
                    />
                  ) : (
                    <ActionButton
                      className={css(styles.cancelButton)}
                      title="Annuler"
                      isLoading={loadingDeleteProject}
                      onClick={() => handleClose()}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box textAlign={"right"}>
                    {extProject ? (
                      <div style={{ display: "flex" }}>
                        <ActionButton
                          className={css(styles.cancelButton)}
                          title="Annuler"
                          isLoading={loadingDeleteProject}
                          onClick={() => handleClose()}
                        />
                        <ActionButton
                          className={css(styles.addBtn)}
                          title="Mettre à jour"
                          isLoading={loadingUpdateProject}
                          onClick={handleUpdateProject}
                        />
                      </div>
                    ) : (
                      <ActionButton
                        className={css(styles.addBtn)}
                        title="Créer"
                        isLoading={loadingCreateProject}
                        onClick={handleCreateProject}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="delete-project-modal-title"
        aria-describedby="delete-project-modal-description"
        className={classes.modal}
        open={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRemoveModal}>
          <div className={classes.paper}>
            <h2 id="delete-project-modal-title">
              Êtes-vous sûr de vouloir supprimer ce projet?
              <br />
              Cette action est irréversible.
            </h2>

            <Grid container spacing={6} justifyContent="space-between" style={{ marginTop: "16px" }}>
              <Grid item xs={6}>
                <ActionButton
                  className={css(styles.cancelButton)}
                  title="Annuler"
                  isLoading={loadingDeleteProject}
                  onClick={() => setOpenRemoveModal(false)}
                />
              </Grid>
              <Grid item xs={6}>
                <ActionButton
                  className={css(styles.deleteBtn)}
                  title="Supprimer"
                  isLoading={loadingDeleteProject}
                  onClick={handleDeleteProject}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
