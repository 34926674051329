// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  updateBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  TableWrpper: {
    width: "100%",
    marginTop: 40,
    height: "calc(100vh - 410px)",
    minHeight: 400,
  },
});
