import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Colors from "../../theme/Colors";
import moment from "moment";
import {
  ADMIN_BASE_URI,
  DATE_FORMAT11,
  INFO_BASE_URI,
  ROUTES,
  USER_PERMISSIONS,
} from "../../constants";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowForward,
  Menu,
  LocalActivity,
  Dashboard,
  Build,
  ExitToApp,
  Person,
  People,
  Business,
  Info,
  LocalOffer,
  Close,
  Settings,
} from "@material-ui/icons";
import RocketLaunchIcon from "../../assets/icons/RocketLaunch";
import { useQuery } from "@apollo/client";
import { GET_FEATURE_FLAGS } from "../../graphql";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Util from "../../services/Util";
import { SET_YC_PARTICIPANTS } from "../../actions/ActionTypes";
import { logoutUser } from "../../helpers/dataHelpers";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  linkStyle: {
    color: Colors.black,
    "&:hover": {
      color: Colors.black,
      textDecoration: "none",
    },
  },
  appBarBg: {
    background: Colors.brand.primary,
    zIndex: theme.zIndex.drawer + 1,
    padding: "6px",
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    padding: 0,
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  daySwitcher: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    minWidth: "280px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      minWidth: "auto",
    },
  },
  youthCenterName: {
    color: "#fff",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "220px",
    height: "24px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "20px",
    textAlign: "right",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "0",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      textOverflow: "initial",
      whiteSpace: "initial",
      overflow: "initial",
      minWidth: "auto",
    },
  },
  headTextBlock: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  centerBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "4px",
    width: "280px",
    height: "24px",
    gap: "0",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "0px",
      width: "auto",
      gap: "10px",
      marginTop: "0",
    },
  },
  switchDayBtn: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoIcon: {
    textDecoration: "none",
    maxHeight: "24px",
  },
}));

export default function HeaderView(props) {
  const { date, title } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.user;
  });
  const ycState = useSelector((state) => {
    return state.youth_center;
  });
  const userRole = user?.role?.name;

  const history = useHistory();
  const classes = useStyles();

  const { data: featureFlagsData } = useQuery(GET_FEATURE_FLAGS);
  const isPreferencesEnabled = featureFlagsData?.featureFlags?.data?.some(
    (flag) => flag.attributes.name === "Preferences" && flag.attributes.enabled
  );

  const fetchParticipantsSearch = async () => {
    const { participants } = await Util.fetchParticipantsSearch({
      user,
      searchTerm: ycState.mergeModalSearchText,
    });

    dispatch({
      type: SET_YC_PARTICIPANTS,
      payload: participants,
    });
  };

  const includeIf = (condition, itemsToInclude) => {
    return condition ? itemsToInclude : [];
  };

  // React.useEffect(() => {
  //   const debounceTimer = setTimeout(() => {
  //     fetchParticipantsSearch();
  //   }, 300);

  //   return () => {
  //     clearTimeout(debounceTimer);
  //   };
  // }, [ycState.mergeModalSearchText]);

  const dashboardMenuItem = {
    id: 1,
    name: "Journal de bord",
    icon: <Dashboard />,
    method: () => {
      history.push(ROUTES.DASHBOARD);
    },
  };

  const activitiesMenuItem = {
    id: 2,
    name: "Activités",
    icon: <LocalActivity />,
    method: () => {
      history.push(ROUTES.ACTIVITIES);
    },
  };

  const coordonnessMenuItem = {
    id: 3,
    name: "Coordonnées",
    icon: <Business />,
    method: () => {
      history.push(ROUTES.YOUTH_CENTERS);
    },
  };

  const participantsMenuItem = {
    id: 4,
    name: "Participants",
    icon: <Person />,
    method: () => {
      history.push(ROUTES.PARTICIPANTS);
    },
  };

  const userMenuItem = {
    id: 5,
    name: "Usagers",
    icon: <People />,
    method: () => {
      history.push(ROUTES.USERS);
    },
  };

  const adminMenuItem = {
    id: 6,
    name: "Administration",
    icon: <Build />,
    method: () => {
      window.location.href = ADMIN_BASE_URI;
    },
  };

  const etiquettesItem = {
    id: 6,
    name: "Étiquettes",
    icon: <LocalOffer />,
    method: () => {
      history.push(ROUTES.ETIQUETTES);
    },
  };

  const projectsItem = {
    id: 7,
    name: "Projets",
    icon: <RocketLaunchIcon />,
    method: () => {
      history.push(ROUTES.PROJECTS);
    },
  };

  const preferencesMenuItem = {
    id: 9,
    name: "Préférences",
    icon: <Settings />,
    method: () => {
      history.push(ROUTES.PREFERENCES);
    },
  };

  const logoutMenuItem = {
    id: 8,
    name: "Déconnexion",
    icon: <ExitToApp />,
    method: () => {
      logoutUser();
    },
  };

  const baseUserMenu = [
    activitiesMenuItem,
    etiquettesItem,
    projectsItem,
    coordonnessMenuItem,
    ...includeIf(isPreferencesEnabled, [preferencesMenuItem])
  ];

  const teamUserMenu = [
    dashboardMenuItem,
    activitiesMenuItem,
    projectsItem,
    participantsMenuItem,
    coordonnessMenuItem,
    ...includeIf(isPreferencesEnabled, [preferencesMenuItem])
  ];

  const directorUserMenu = [
    dashboardMenuItem,
    activitiesMenuItem,
    etiquettesItem,
    projectsItem,
    participantsMenuItem,
    userMenuItem,
    coordonnessMenuItem,
    ...includeIf(isPreferencesEnabled, [preferencesMenuItem])
  ];

  const adminUserMenu = [
    dashboardMenuItem,
    activitiesMenuItem,
    etiquettesItem,
    projectsItem,
    participantsMenuItem,
    userMenuItem,
    coordonnessMenuItem,
    ...includeIf(isPreferencesEnabled, [preferencesMenuItem])
  ];

  const adminLimitedUserMenu = [
    activitiesMenuItem,
    userMenuItem,
    coordonnessMenuItem,
    etiquettesItem,
    projectsItem,
    ...includeIf(isPreferencesEnabled, [preferencesMenuItem])
  ];

  const menuItems = [logoutMenuItem];

  switch (userRole) {
    case USER_PERMISSIONS.Authenticated:
      menuItems.unshift(...baseUserMenu);
      break;
    case USER_PERMISSIONS.RMJQ:
      menuItems.unshift(...baseUserMenu);
      break;
    case USER_PERMISSIONS.MDJ_TEAM:
      menuItems.unshift(...teamUserMenu);
      break;
    case USER_PERMISSIONS.MDJ_DIRECTOR:
      menuItems.unshift(...directorUserMenu);
      break;
    case USER_PERMISSIONS.ADMINISTRATOR:
      menuItems.unshift(...adminUserMenu);
      break;
    case USER_PERMISSIONS.ADMINISTRATOR_LIMITED:
      menuItems.unshift(...adminLimitedUserMenu);
      break;
    default:
      console.error(`Role "${userRole}" not supported!`);
      break;
  }

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const daySwitcher = () => {
    const parsedDate = moment(date, "DDMMYYYY");
    const today = moment().startOf("day");

    const getNextDay = (date) => moment(date).add(1, "days");
    const getPreviousDay = (date) => moment(date).subtract(1, "days");

    const navigateToDay = (date) => {
      const formattedDate = date.format("DDMMYYYY");
      history.push(`/day/${formattedDate}`);
    };

    const handleNextDay = () => {
      const nextDay = getNextDay(parsedDate);
      navigateToDay(nextDay);
    };

    const handlePreviousDay = () => {
      const previousDay = getPreviousDay(parsedDate);
      navigateToDay(previousDay);
    };
    return (
      <div>
        <IconButton edge="start" color="inherit" onClick={handlePreviousDay}>
          <ArrowBack />
        </IconButton>
        {date &&
          moment(date.toLocaleString()).locale("fr").format(DATE_FORMAT11)}
        {parsedDate.isBefore(today) && (
          <IconButton edge="end" color="inherit" onClick={handleNextDay}>
            <ArrowForward />
          </IconButton>
        )}
      </div>
    );
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBarBg} position="fixed">
        <Toolbar className={classes.toolBar}>
          {open === false ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <Menu />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerClose}
              className={classes.menuButton}
            >
              <Close />
            </IconButton>
          )}

          <Typography variant="h6" className={classes.title}>
            {window.location.pathname.startsWith(ROUTES.DAY)
              ? daySwitcher()
              : title}
          </Typography>

          <span
            style={{
              color: "#fff",
              marginRight: "5px",
              marginBottom: "3px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(ROUTES.YOUTH_CENTERS);
            }}
          >
            {user.youth_center.Name}
          </span>
          {/* Info Icon  */}
          <a
            href={INFO_BASE_URI}
            style={{ color: Colors.white }}
            target="_blank"
            rel="noreferrer"
          >
            <Info fontSize="large" />
          </a>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}></div>

        <List style={{ marginTop: 25 }}>
          {menuItems.map((item, index) => (
            <Link
              key={index}
              onClick={item.method}
              className={classes.linkStyle}
            >
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
